import {useEffect} from 'react'
import {useIntl} from 'react-intl'
import {PageTitle} from '../../../_metronic/layout/core'
import {MixedWidget5} from '../../../_metronic/partials/widgets'
// import {useSearchParams} from 'react-router-dom'
// import Modal from 'react-bootstrap/Modal'
// import Button from 'react-bootstrap/Button'
const DashboardPage = () => {
  // const [searchParams] = useSearchParams();
  // const message = searchParams.get('message');
  // const [showModal, setShowModal] = useState(false);
  useEffect(() => {
    // We have to show toolbar only for dashboard page
    document.getElementById('kt_layout_toolbar')?.classList.remove('d-none')
    return () => {
      document.getElementById('kt_layout_toolbar')?.classList.add('d-none')
    }
  }, [])
  // useEffect(() => {
  //   // Show the modal when a message is received
  //   if (message) {
  //     setShowModal(true);
  //   }
  // }, [message]);

  // const handleClose = () => {
  //   setShowModal(false);
  // };

  return (
    <>
      {/* <Modal show={showModal} onHide={handleClose}>
    <Modal.Header closeButton>
      <Modal.Title>Message Received</Modal.Title>
    </Modal.Header>
    <Modal.Body>
      {message && <p>{decodeURIComponent(message)}</p>}
    </Modal.Body>
    <Modal.Footer>
      <Button variant="secondary" onClick={handleClose}>
        Close
      </Button>
    </Modal.Footer>
  </Modal> */}

      <div className='container text-center card text-bg-light '>
        <div className='row d-flex justify-content-center align-content-center mb-18'>
          <div className='col-md-6'>
            <MixedWidget5
              className='card-xl-stretch mb-5 mb-md-0 m-md-10 mt-md-20'
              image='\media\sanomoov\sanomoov-icon.svg'
              title='Protocole'
              description='
            Description<br/>
                Protocole.
            '
            />
          </div>
          <div className='col-md-6'>
            <MixedWidget5
              className='card-xl-stretch mb-5 mb-md-0 m-md-10 mt-md-20'
              image='\media\sanomoov\sanomoov-icon.svg'
              title='Exercices'
              description='
            Description<br/>
            Exercice.
            '
            />
          </div>
        </div>
      </div>
      {/* end::Row */}
    </>
  )
}

const DashboardWrapper = () => {
  const intl = useIntl()
  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.DASHBOARD'})}</PageTitle>
      <DashboardPage />
    </>
  )
}

export {DashboardWrapper}
