/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import {toAbsoluteUrl} from '../../../helpers'

type Props = {
  className: string

  image: string
  title: string
  description: string
}

const MixedWidget5: React.FC<Props> = ({className, image, title, description}) => {
  return (
    <div className={`card ${className}`}>
      {/* begin::Body */}
      <div className='card-body d-flex flex-column pb-10 pb-lg-15'>
        <div className='flex-grow-1'>
          {/* begin::Info */}
          <div className='d-flex align-items-center pe-2 mb-5'>
          

            <div className='symbol symbol-50px'>
              <span className='symbol-label bg-light'>
                <img src={toAbsoluteUrl(image)} className='h-50 align-self-center' alt='' />
              </span>
            </div>
          </div>
          {/* end::Info */}

          {/* begin::Link */}
          <a href={toAbsoluteUrl('/app/bases/index')} className='text-dark fw-bold text-hover-primary fs-4'>
            {title}
          </a>
          {/* end::Link */}

          {/* begin::Desc */}
          <p className='py-3' dangerouslySetInnerHTML={{__html: description}}></p>
          {/* end::Desc */}
        </div>

        {/* begin::Team */}
        
        {/* end::Team */}
      </div>
      {/* end::Body */}
    </div>
  )
}

export {MixedWidget5}
